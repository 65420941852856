<template>
  <div v-if="isLoading" class="d-flex flex-column justify-content-center">
    <hs-loading />
  </div>
  <section v-else>
    <hsPageHeader
      :title="additional.title"
      :back-text="$t('sparkmembers.contents.views.editor.components.additional.components.page-header.back')"
      back-route="ContentsList"
    />
    <section class="container-fluid">
      <div class="row mt-5 px-4">
        <div class="col-xl-9">
          <MainMedia
            type="additional"
            :types="types"
            :media="media"
            @save="onMainMediaSaved"
            @premium="onPremiumFeature"
          />

          <section class="bg-white p-4 mb-5 rounded-sm">
            <h5 class="font-weight-bold mb-2">
              {{ $t('sparkmembers.contents.views.editor.components.additional.index.additional-details.title') }}
            </h5>

            <p class="font-size-sm p-0 mb-3">
              {{ $t('sparkmembers.contents.views.editor.components.additional.index.additional-details.subtitle') }}
            </p>
            <hs-group
              class="mb-4"
              label-for="edit-additional-title"
              :label="
                $t('sparkmembers.contents.views.editor.components.additional.index.additional-details.name-group.label')
              "
              label-class="font-weight-bold"
            >
              <hs-input
                id="edit-additional-title"
                :placeholder="
                  $t(
                    'sparkmembers.contents.views.editor.components.additional.index.additional-details.name-group.input-placeholder'
                  )
                "
                v-model="additional.title"
              />
            </hs-group>
            <div class="d-flex justify-content-end">
              <hs-button variant="outline-secondary" @click="onEdit">{{
                $t('sparkmembers.contents.views.editor.components.additional.index.additional-details.save')
              }}</hs-button>
            </div>
          </section>

          <section class="d-flex flex-column flex-md-row mb-5">
            <div class="align-self-center align-self-md-start flex-md-grow-1">
              <hs-button icon="times-circle" variant="danger" v-b-modal="'delete-additional-modal'">
                {{
                  $t('sparkmembers.contents.views.editor.components.additional.index.buttons.delete-additional')
                }}</hs-button
              >
            </div>

            <hs-button variant="outline-success-dark" :disabled="isUpdating" @click="onEditAdditionalAndBack">
              {{
                isUpdating
                  ? $t('sparkmembers.contents.views.editor.components.additional.index.buttons.saving')
                  : $t('sparkmembers.contents.views.editor.components.additional.index.buttons.save-and-return')
              }}
            </hs-button>

            <hs-button
              class="ml-0 mt-2 mt-md-0 ml-md-4"
              variant="success-dark"
              :disabled="!nextAdditionalId"
              @click="onEditAdditionalAndEditNext"
            >
              {{
                isUpdating
                  ? $t('sparkmembers.contents.views.editor.components.additional.index.buttons.saving')
                  : $t(
                      'sparkmembers.contents.views.editor.components.additional.index.buttons.save-and-edit-next-additional'
                    )
              }}
            </hs-button>
          </section>
        </div>
        <div class="col-xl-3">
          <div class="d-flex flex-column">
            <VisibilityCard v-model="additional.available" @input="onEdit()" />
            <DownloadCard v-if="media && media.type !== 'Video'" v-model="additional.downloadable" @input="onEdit()" />
            <AdditionalReleaseCard v-model="additional.schedule" @input="onEdit()" />
          </div>
        </div>
      </div>
    </section>
    <hsConfirmModal
      id="delete-additional-modal"
      icon="trash-alt"
      variant="cherry"
      hide-header
      :customTitle="
        $t('sparkmembers.contents.views.editor.components.additional.index.confirm-box.delete-additional.content-title')
      "
      :description="
        $t(
          'sparkmembers.contents.views.editor.components.additional.index.confirm-box.delete-additional.content-description'
        )
      "
      :ok-title="
        $t('sparkmembers.contents.views.editor.components.additional.index.confirm-box.delete-additional.ok-title')
      "
      :cancel-title="
        $t('sparkmembers.contents.views.editor.components.additional.index.confirm-box.delete-additional.cancel-title')
      "
      @ok="onDelete"
    />
    <hsPremiumModal id="premium-modal" @click="requestPremiumFeature" />
  </section>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import analyticsService from '@/services/analytics';
import mediaService from '@/sparkmembers/services/media';
import extraContentsService from '@/sparkmembers/services/extraContents';
import { hsLoading, hsConfirmModal, hsPremiumModal } from '@/components';
import ToastHelper from '@/shared/helpers/toast';
import MainMedia from '../MainMedia';
import hsPageHeader from '@/components/_structures/PageHeader';
import VisibilityCard from './components/VisibilityCard';
import DownloadCard from './components/DownloadCard';
import AdditionalReleaseCard from './components/AdditionalReleaseCard';
import AvailableAdditionalMedia from '@/data/additional_content_media_types';
import debug from 'debug';

const logging = debug('hs:additional_material');

export default {
  name: 'Additional',
  components: {
    hsConfirmModal,
    hsLoading,
    hsPremiumModal,
    MainMedia,
    hsPageHeader,
    VisibilityCard,
    DownloadCard,
    AdditionalReleaseCard,
  },
  data() {
    return {
      types: AvailableAdditionalMedia(key => this.$t(key)),
      additional: {
        id: null,
        title: '',
        downloadable: '',
        available: '',
        schedule: {
          release_after: '',
          release_at: '',
        },
      },
      media: null,
      isLoading: true,
      isUpdating: false,
      nextAdditionalId: null,
    };
  },
  computed: {
    ...mapState({
      course: state => state.course.selectedCourse,
      selectedSchool: state => state.school.selectedSchool,
    }),
  },
  mounted() {
    this.loadAdditional();
  },
  methods: {
    ...mapActions('school', ['updateSchool']),
    async onEditAdditionalAndBack() {
      this.isUpdating = true;
      await this.onEdit();

      if (this.course.kind == 'live_meet') {
        this.$router.push({ name: 'ContentLives', params: { additionalTab: 'active', courseId: this.course.id } });
      } else this.$router.push({ name: 'Contents' });
    },
    async onEditAdditionalAndEditNext() {
      this.isUpdating = true;
      this.$router.push({
        name: 'ContentEditor',
        params: { contentId: this.nextAdditionalId },
        query: { type: 'Additional' },
      });

      this.media = null;

      await this.onEdit();
      await this.loadAdditional();
      window.scrollTo(0, 0);
      this.isUpdating = false;
    },
    async onMainMediaSaved(media) {
      this.isLoading = true;
      try {
        const newMedia = (await mediaService.create({ media })).data;

        await extraContentsService.update({
          id: this.additional.id,
          media_id: newMedia.id,
        });

        this.media = newMedia;
        ToastHelper.successMessage(
          this.$t('sparkmembers.contents.views.editor.components.additional.index.toast.main-media-save.success')
        );
      } catch (e) {
        ToastHelper.dangerMessage(
          this.$t('sparkmembers.contents.views.editor.components.additional.index.toast.main-media-save.error')
        );
      } finally {
        this.isLoading = false;
      }
    },
    async onEdit() {
      try {
        await extraContentsService.update({
          ...this.additional,
          ...this.additional.schedule,
        });
        ToastHelper.successMessage(
          this.$t('sparkmembers.contents.views.editor.components.additional.index.toast.details-save.success')
        );
      } catch (e) {
        ToastHelper.dangerMessage(
          this.$t('sparkmembers.contents.views.editor.components.additional.index.toast.details-save.error')
        );
      }
    },
    async onDelete() {
      this.isLoading = true;
      try {
        await extraContentsService.delete(this.additional.id);
        ToastHelper.successMessage(
          this.$t('sparkmembers.contents.views.editor.components.additional.index.toast.deleted-additional.success')
        );
      } catch (error) {
        ToastHelper.dangerMessage(
          this.$t('sparkmembers.contents.views.editor.components.additional.index.toast.deleted-additional.error')
        );
      } finally {
        if (this.course.kind == 'live_meet') {
          this.$router.push({ name: 'ContentLives', params: { additionalTab: 'active', courseId: this.course.id } });
        } else this.$router.push({ name: 'Contents' });
      }
    },
    async loadAdditional() {
      try {
        this.isLoading = true;
        const {
          id,
          title,
          downloadable,
          available,
          release_after,
          release_at,
          media,
          next_extra_content_id,
        } = await extraContentsService.getById(this.$route.params.contentId);

        this.additional = { id, title, downloadable, available, schedule: { release_after, release_at } };
        this.nextAdditionalId = next_extra_content_id;

        if (media) {
          const { data } = await mediaService.get(media.id);
          this.media = data;
        }
      } catch (e) {
        logging('Erro ao carregar lista de materiais adicionais');
      } finally {
        this.isLoading = false;
      }
    },
    async onPremiumFeature(feature) {
      this.premiumFeatureLocation = `lesson ${feature.toLowerCase()}`;
      this.$bvModal.show('premium-modal');
      analyticsService.track({
        event: 'Paywall displayed',
        props: {
          paywallReason: this.premiumFeatureLocation,
        },
      });
      const school = this.selectedSchool;
      school.extra_settings.show_top_bar = 'true';
      await this.updateSchool({ id: school.id, extra_settings: school.extra_settings });
    },
    requestPremiumFeature() {
      this.$router.push({ name: 'MyAccountPlans' });
      this.$bvModal.hide('premium-modal');
    },
  },
};
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isLoading)?_c('div',{staticClass:"d-flex flex-column justify-content-center"},[_c('hs-loading')],1):_c('section',[_c('hsPageHeader',{attrs:{"title":_vm.additional.title,"back-text":_vm.$t('sparkmembers.contents.views.editor.components.additional.components.page-header.back'),"back-route":"ContentsList"}}),_vm._v(" "),_c('section',{staticClass:"container-fluid"},[_c('div',{staticClass:"row mt-5 px-4"},[_c('div',{staticClass:"col-xl-9"},[_c('MainMedia',{attrs:{"type":"additional","types":_vm.types,"media":_vm.media},on:{"save":_vm.onMainMediaSaved,"premium":_vm.onPremiumFeature}}),_vm._v(" "),_c('section',{staticClass:"bg-white p-4 mb-5 rounded-sm"},[_c('h5',{staticClass:"font-weight-bold mb-2"},[_vm._v("\n            "+_vm._s(_vm.$t('sparkmembers.contents.views.editor.components.additional.index.additional-details.title'))+"\n          ")]),_vm._v(" "),_c('p',{staticClass:"font-size-sm p-0 mb-3"},[_vm._v("\n            "+_vm._s(_vm.$t('sparkmembers.contents.views.editor.components.additional.index.additional-details.subtitle'))+"\n          ")]),_vm._v(" "),_c('hs-group',{staticClass:"mb-4",attrs:{"label-for":"edit-additional-title","label":_vm.$t('sparkmembers.contents.views.editor.components.additional.index.additional-details.name-group.label'),"label-class":"font-weight-bold"}},[_c('hs-input',{attrs:{"id":"edit-additional-title","placeholder":_vm.$t(
                  'sparkmembers.contents.views.editor.components.additional.index.additional-details.name-group.input-placeholder'
                )},model:{value:(_vm.additional.title),callback:function ($$v) {_vm.$set(_vm.additional, "title", $$v)},expression:"additional.title"}})],1),_vm._v(" "),_c('div',{staticClass:"d-flex justify-content-end"},[_c('hs-button',{attrs:{"variant":"outline-secondary"},on:{"click":_vm.onEdit}},[_vm._v(_vm._s(_vm.$t('sparkmembers.contents.views.editor.components.additional.index.additional-details.save')))])],1)],1),_vm._v(" "),_c('section',{staticClass:"d-flex flex-column flex-md-row mb-5"},[_c('div',{staticClass:"align-self-center align-self-md-start flex-md-grow-1"},[_c('hs-button',{directives:[{name:"b-modal",rawName:"v-b-modal",value:('delete-additional-modal'),expression:"'delete-additional-modal'"}],attrs:{"icon":"times-circle","variant":"danger"}},[_vm._v("\n              "+_vm._s(_vm.$t('sparkmembers.contents.views.editor.components.additional.index.buttons.delete-additional')))])],1),_vm._v(" "),_c('hs-button',{attrs:{"variant":"outline-success-dark","disabled":_vm.isUpdating},on:{"click":_vm.onEditAdditionalAndBack}},[_vm._v("\n            "+_vm._s(_vm.isUpdating
                ? _vm.$t('sparkmembers.contents.views.editor.components.additional.index.buttons.saving')
                : _vm.$t('sparkmembers.contents.views.editor.components.additional.index.buttons.save-and-return'))+"\n          ")]),_vm._v(" "),_c('hs-button',{staticClass:"ml-0 mt-2 mt-md-0 ml-md-4",attrs:{"variant":"success-dark","disabled":!_vm.nextAdditionalId},on:{"click":_vm.onEditAdditionalAndEditNext}},[_vm._v("\n            "+_vm._s(_vm.isUpdating
                ? _vm.$t('sparkmembers.contents.views.editor.components.additional.index.buttons.saving')
                : _vm.$t(
                    'sparkmembers.contents.views.editor.components.additional.index.buttons.save-and-edit-next-additional'
                  ))+"\n          ")])],1)],1),_vm._v(" "),_c('div',{staticClass:"col-xl-3"},[_c('div',{staticClass:"d-flex flex-column"},[_c('VisibilityCard',{on:{"input":function($event){return _vm.onEdit()}},model:{value:(_vm.additional.available),callback:function ($$v) {_vm.$set(_vm.additional, "available", $$v)},expression:"additional.available"}}),_vm._v(" "),(_vm.media && _vm.media.type !== 'Video')?_c('DownloadCard',{on:{"input":function($event){return _vm.onEdit()}},model:{value:(_vm.additional.downloadable),callback:function ($$v) {_vm.$set(_vm.additional, "downloadable", $$v)},expression:"additional.downloadable"}}):_vm._e(),_vm._v(" "),_c('AdditionalReleaseCard',{on:{"input":function($event){return _vm.onEdit()}},model:{value:(_vm.additional.schedule),callback:function ($$v) {_vm.$set(_vm.additional, "schedule", $$v)},expression:"additional.schedule"}})],1)])])]),_vm._v(" "),_c('hsConfirmModal',{attrs:{"id":"delete-additional-modal","icon":"trash-alt","variant":"cherry","hide-header":"","customTitle":_vm.$t('sparkmembers.contents.views.editor.components.additional.index.confirm-box.delete-additional.content-title'),"description":_vm.$t(
        'sparkmembers.contents.views.editor.components.additional.index.confirm-box.delete-additional.content-description'
      ),"ok-title":_vm.$t('sparkmembers.contents.views.editor.components.additional.index.confirm-box.delete-additional.ok-title'),"cancel-title":_vm.$t('sparkmembers.contents.views.editor.components.additional.index.confirm-box.delete-additional.cancel-title')},on:{"ok":_vm.onDelete}}),_vm._v(" "),_c('hsPremiumModal',{attrs:{"id":"premium-modal"},on:{"click":_vm.requestPremiumFeature}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
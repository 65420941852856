<template>
  <section class="bg-white p-4 mb-5 rounded-sm">
    <h5 class="font-weight-bold mb-3">
      {{ $t('sparkmembers.contents.views.editor.components.additional.components.additional-release-card.title') }}
    </h5>
    <template v-if="step == 1">
      <p class="font-size-sm">
        {{
          $t(
            'sparkmembers.contents.views.editor.components.additional.components.additional-release-card.paragraph.pt1'
          )
        }}
        <strong class="text-primary">
          {{
            $t(
              'sparkmembers.contents.views.editor.components.additional.components.additional-release-card.paragraph.pt2'
            )
          }}
          <template v-if="selectedScheduleRelease === 'not_schedule'">
            {{
              $t(
                'sparkmembers.contents.views.editor.components.additional.components.additional-release-card.paragraph.released-without-requirement'
              )
            }}
          </template>
          <template v-else>
            {{
              $t(
                'sparkmembers.contents.views.editor.components.additional.components.additional-release-card.paragraph.scheduled-release'
              )
            }}
          </template>
        </strong>
      </p>

      <hs-button class="mt-3" variant="outline-secondary" @click="onChangeRelease">{{
        $t(
          'sparkmembers.contents.views.editor.components.additional.components.additional-release-card.buttons.change-navigation'
        )
      }}</hs-button>
    </template>
    <template v-if="step == 2">
      <div class="mb-3">
        <hs-radio class="mt-2" name="release" v-model="selectedScheduleRelease" value="not_schedule">
          {{
            $t(
              'sparkmembers.contents.views.editor.components.additional.components.additional-release-card.release.radio.keep-all-times'
            )
          }}
        </hs-radio>
        <hs-radio class="mt-2" name="release" v-model="selectedScheduleRelease" value="release_after">
          {{
            $t(
              'sparkmembers.contents.views.editor.components.additional.components.additional-release-card.release.radio.release-text'
            )
          }}
          <hs-input
            type="number"
            v-model="schedule.release_after"
            :disabled="selectedScheduleRelease !== 'release_after'"
          />
          {{
            $t(
              'sparkmembers.contents.views.editor.components.additional.components.additional-release-card.release.radio.days-after-enrolment'
            )
          }}
        </hs-radio>
        <hs-radio class="mt-2" name="release" v-model="selectedScheduleRelease" value="release_at">
          <span class="mr-1">
            {{
              $t(
                'sparkmembers.contents.views.editor.components.additional.components.additional-release-card.release.radio.release-on'
              )
            }}
          </span>
          <hs-date
            v-model="releaseDate"
            :label-no-date-selected="$t('date.format')"
            size="sm"
            :disabled="selectedScheduleRelease !== 'release_at'"
            :min="new Date()"
            value-as-date
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
          />
        </hs-radio>
      </div>
      <hs-button class="mt-3" variant="outline-secondary" @click="onSave">{{
        $t('sparkmembers.contents.views.editor.components.additional.components.additional-release-card.buttons.save')
      }}</hs-button>
    </template>
  </section>
</template>

<script>
import dayjs from 'dayjs';
export default {
  name: 'AdditionalReleaseCard',
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selectedScheduleRelease: 'not_schedule',
      step: 1,
      schedule: this.value,
    };
  },
  watch: {
    selectedScheduleRelease(_, oldValue) {
      this.schedule[oldValue] = null;
    },
  },
  methods: {
    onChangeRelease() {
      for (const [key, value] of Object.entries(this.schedule)) {
        if (key.includes('release') && value !== null) this.selectedScheduleRelease = key;
      }

      this.step++;
    },
    async onSave() {
      this.$emit('input', this.schedule);
      this.step = 1;
    },
  },
  computed: {
    releaseDate: {
      get() {
        return this.schedule.release_at ? dayjs(this.schedule.release_at).toDate() : null;
      },
      set(newDate) {
        this.schedule.release_at = newDate.toISOString();
      },
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .hs-input {
  display: inline-block;
  width: 55px;
  height: 15px;

  input[type='number'] {
    height: 27px;
  }
}

/deep/ .custom-radio:last-child {
  .custom-control-label {
    display: flex;
    flex-wrap: wrap;
  }
}

/deep/ .b-form-datepicker {
  width: auto;
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"bg-white p-4 mb-5 rounded-sm"},[_c('h5',{staticClass:"font-weight-bold mb-3"},[_vm._v("\n    "+_vm._s(_vm.$t('sparkmembers.contents.views.editor.components.additional.components.additional-release-card.title'))+"\n  ")]),_vm._v(" "),(_vm.step == 1)?[_c('p',{staticClass:"font-size-sm"},[_vm._v("\n      "+_vm._s(_vm.$t(
          'sparkmembers.contents.views.editor.components.additional.components.additional-release-card.paragraph.pt1'
        ))+"\n      "),_c('strong',{staticClass:"text-primary"},[_vm._v("\n        "+_vm._s(_vm.$t(
            'sparkmembers.contents.views.editor.components.additional.components.additional-release-card.paragraph.pt2'
          ))+"\n        "),(_vm.selectedScheduleRelease === 'not_schedule')?[_vm._v("\n          "+_vm._s(_vm.$t(
              'sparkmembers.contents.views.editor.components.additional.components.additional-release-card.paragraph.released-without-requirement'
            ))+"\n        ")]:[_vm._v("\n          "+_vm._s(_vm.$t(
              'sparkmembers.contents.views.editor.components.additional.components.additional-release-card.paragraph.scheduled-release'
            ))+"\n        ")]],2)]),_vm._v(" "),_c('hs-button',{staticClass:"mt-3",attrs:{"variant":"outline-secondary"},on:{"click":_vm.onChangeRelease}},[_vm._v(_vm._s(_vm.$t(
        'sparkmembers.contents.views.editor.components.additional.components.additional-release-card.buttons.change-navigation'
      )))])]:_vm._e(),_vm._v(" "),(_vm.step == 2)?[_c('div',{staticClass:"mb-3"},[_c('hs-radio',{staticClass:"mt-2",attrs:{"name":"release","value":"not_schedule"},model:{value:(_vm.selectedScheduleRelease),callback:function ($$v) {_vm.selectedScheduleRelease=$$v},expression:"selectedScheduleRelease"}},[_vm._v("\n        "+_vm._s(_vm.$t(
            'sparkmembers.contents.views.editor.components.additional.components.additional-release-card.release.radio.keep-all-times'
          ))+"\n      ")]),_vm._v(" "),_c('hs-radio',{staticClass:"mt-2",attrs:{"name":"release","value":"release_after"},model:{value:(_vm.selectedScheduleRelease),callback:function ($$v) {_vm.selectedScheduleRelease=$$v},expression:"selectedScheduleRelease"}},[_vm._v("\n        "+_vm._s(_vm.$t(
            'sparkmembers.contents.views.editor.components.additional.components.additional-release-card.release.radio.release-text'
          ))+"\n        "),_c('hs-input',{attrs:{"type":"number","disabled":_vm.selectedScheduleRelease !== 'release_after'},model:{value:(_vm.schedule.release_after),callback:function ($$v) {_vm.$set(_vm.schedule, "release_after", $$v)},expression:"schedule.release_after"}}),_vm._v("\n        "+_vm._s(_vm.$t(
            'sparkmembers.contents.views.editor.components.additional.components.additional-release-card.release.radio.days-after-enrolment'
          ))+"\n      ")],1),_vm._v(" "),_c('hs-radio',{staticClass:"mt-2",attrs:{"name":"release","value":"release_at"},model:{value:(_vm.selectedScheduleRelease),callback:function ($$v) {_vm.selectedScheduleRelease=$$v},expression:"selectedScheduleRelease"}},[_c('span',{staticClass:"mr-1"},[_vm._v("\n          "+_vm._s(_vm.$t(
              'sparkmembers.contents.views.editor.components.additional.components.additional-release-card.release.radio.release-on'
            ))+"\n        ")]),_vm._v(" "),_c('hs-date',{attrs:{"label-no-date-selected":_vm.$t('date.format'),"size":"sm","disabled":_vm.selectedScheduleRelease !== 'release_at',"min":new Date(),"value-as-date":"","date-format-options":{ year: 'numeric', month: 'numeric', day: 'numeric' }},model:{value:(_vm.releaseDate),callback:function ($$v) {_vm.releaseDate=$$v},expression:"releaseDate"}})],1)],1),_vm._v(" "),_c('hs-button',{staticClass:"mt-3",attrs:{"variant":"outline-secondary"},on:{"click":_vm.onSave}},[_vm._v(_vm._s(_vm.$t('sparkmembers.contents.views.editor.components.additional.components.additional-release-card.buttons.save')))])]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }